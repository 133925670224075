import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Row, Col, Alert, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  client_LandingPage,
  client_uploadDocuments,
} from "../../constants/routes";
import EmployeeTitleBar from "../../components/firmadmin/EmployeeTitleBar";
import {
  useLazyGetDocumentTypeByDocTypeidQuery,
  useUploadwilldocumentMutation,
  useUploadotherdocumentMutation,
  useGetAllDocsByRootIdQuery,
  documentApiManagement,
} from "../../slices/documentApiManagement";
import Loader from "../../components/Loader/Loader";
import { useDropzone } from "react-dropzone";
import {
  defaultDocType,
  videosigning,
  wills,
} from "../../constants/documentFileType";
import DocumentPreview from "../../components/modal/DocumentPreview";
import DocumentDetails from "../../components/Documents/DocumentDetails";
import { useDispatch, useSelector } from "react-redux";
import { useGetClientQuery } from "../../slices/attorneyApiManagementSlice";
import axios from "axios";
import { ROLE } from "../../constants/role";
import ReactGA from "react-ga4";
import { clientTitle } from "../../constants/client/labels";

export default function UploadClientDocuments() {
  const location = useLocation();
  const [items, setItems] = useState([
    {
      id: "item-1",
      link: client_LandingPage,
      content: "--",
      active: true,
      state: { user: "", activeTab: "" },
    },
    {
      id: "item-2",
      link: client_uploadDocuments,
      content: "--",
      active: false,
      state: { id: "", activeTab: "" },
    },
  ]);
  const [documentTypes, setDocumentTypes] = useState(defaultDocType);
  const [selectedDropdown, setDropdown] = useState("");
  const [acceptedFiles, setAcceptedFiles] = useState({});
  const [maxUploadFiles, setMaxUploadFiles] = useState("");
  const [isMultiple, setMultiple] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [content, setFileContent] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isSubmitted, setSubmitClicked] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [radioSelect, setRadioSelect] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [progressMap, setProgressMap] = useState({});
  const [isUploadLoading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const rootDocId = location?.state?.doc?.rootDocId;
  const userId = location?.state?.doc?.userId;
  const [
    trigger,
    { isLoading: documentTypeLoading, error: documentTypeError },
  ] = useLazyGetDocumentTypeByDocTypeidQuery();
  const { data: allDocumentsData, isLoading: getAllDocsLoading } =
    useGetAllDocsByRootIdQuery(
      { rootDocId, userId },
      {
        skip: rootDocId && userId === undefined,
        refetchOnMountOrArgChange: true,
      }
    );
  const dispatch = useDispatch();
  const onDropAccepted = async (files) => {
    if (selectedDropdown === "DT_001") {
      const acceptedFiles = files[0];
      setShowModal(true);
      setFileName(acceptedFiles?.name);
      setSelectedDocs(acceptedFiles);
      setRadioSelect(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([new Uint8Array(e.target.result)]);
        const blobUrl = URL.createObjectURL(blob);
        setFileContent(blobUrl);
      };

      reader.readAsArrayBuffer(acceptedFiles);
    } else {
      setShowModal(false);
      const data = {
        rootDocId: location?.state?.doc?.rootDocId,
        docTypeId: selectedDropdown,
        clientId: location?.state?.doc?.userId,
        userId: userInfo?.userId,
      };
      const fileData = new FormData();
      for (const file of files) {
        fileData.append("documents", file);
      }
      uploaddocumentotherFiles({ fileData, data });
    }
  };
  const onDropRejected = (params) => {
    if (params[0]?.errors[0]?.code === "file-invalid-type") {
      if (selectedDropdown === "DT_001") {
        toast.error("This format is not valid! Please upload PDF format only");
      } else if (selectedDropdown === "DT_002") {
        toast.error("This format is not valid! Please upload MP4 format only");
      } else {
        toast.error(
          "This format is not valid! Please upload PDF and DOCX format only"
        );
      }
    }

    if (params[0]?.errors[0]?.code === "too-many-files") {
      toast.error("Maximum 10 files are allowed");
    }
  };
  const onFileDialogOpen = () => {
    setFileContent(null);
  };
  const [uploadwilldocument, { isLoading: uploadLoading }] =
    useUploadwilldocumentMutation();
  const [uploadotherdocument, { isLoading: uploadOthersLoading }] =
    useUploadotherdocumentMutation();
  const { data, isLoading } = useGetClientQuery(userInfo?.userId, {
    skip: userInfo?.userId === undefined,
  });
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptedFiles,
    noDrag: true,
    noClick: true,
    maxFiles: maxUploadFiles,
    noKeyboard: true,
    multiple: isMultiple,
    onDropAccepted,
    onDropRejected,
    onFileDialogOpen,
  });

  const getDocumentTypes = useCallback(async () => {
    if (location?.state?.doc && location?.state?.doc?.rootDocId) {
      const result = await trigger(location?.state?.doc?.rootDocId).unwrap();
      if (result && result.length > 0) {
        setDocumentTypes(result);
      } else {
        toast.error(result?.message);
      }
    }
  }, [location?.state, trigger]);
  const uploaddocumentwillFiles = useCallback(
    async ({ fileData, data }) => {
      setShowModal(false);
      const fileNames = fileData.getAll("file").map((file) => file.name);
      const fileSizes = fileData.getAll("file").map((file) => {
        return file.size;
      });
      const totalFileSize = fileSizes.reduce(
        (acc, fileSize) => acc + fileSize,
        0
      );
      const isorgWillDocRetain =
        sessionStorage.getItem("orgDoc") === "Yes" ? true : false;
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/will-documents?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}&orgDocsFlag=${isorgWillDocRetain}`;
        const token = userInfo?.accessToken;
        const response = await axios({
          url: baseUrl,
          method: "POST",
          responseType: "json",
          headers: { Authorization: "Bearer " + token },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;
            if (totalLength !== null) {
              setLoading(true);
              const progress = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
              // Update progress for this specific file
              setProgressMap((prevProgressMap) => ({
                ...prevProgressMap,
                [data.docTypeId]: { progress, fileNames },
              }));
            }
          },
          data: fileData,
        });

        if (response.status === 200) {
          setLoading(false);
          sessionStorage.removeItem("orgDoc");
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`upload_will_document_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Upload Will Document Confirmation - ${ROLE.client} - 3 Level`,
              label: `Upload Will Document Confirmation - ${ROLE.client} - 3 Level`,
              wills_uploaded: fileNames.length,
              wills_filesize: totalFileSize,
            });

          firmType === 2 &&
            ReactGA.event(`upload_will_document_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Upload Will Document Confirmation - ${ROLE.client} - 2 Level`,
              label: `Upload Will Document Confirmation - ${ROLE.client} - 2 Level`,
              wills_uploaded: fileNames.length,
              wills_filesize: totalFileSize,
            });
          dispatch(
            documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
          );
          toast(
            <div>
              <div className="notification">Notification</div>
              <div className="upload mt-2">{response?.data?.message}</div>
              <div className="upload mt-2">
                Please Upload the Signing Videos of the Will.
              </div>
            </div>,
            {
              autoClose: true,
              closeOnClick: false,
              theme: "light",
              closeButton: true,
              hideProgressBar: true,
              className: "custom-toast-video-sigining",
            }
          );
          setTimeout(() => {
            setProgressMap((prevProgressMap) => {
              const updatedProgressMap = { ...prevProgressMap };
              delete updatedProgressMap[data.docTypeId];
              return updatedProgressMap;
            });
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || `File uploading failed. Try again!`
        );
        setProgressMap((prevProgressMap) => {
          const updatedProgressMap = { ...prevProgressMap };
          delete updatedProgressMap[data.docTypeId];
          return updatedProgressMap;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadwilldocument]
  );

  const uploaddocumentotherFiles = useCallback(
    async ({ fileData, data }) => {
      setShowModal(false);
      const fileNames = fileData.getAll("documents").map((file) => file.name);
      const fileSizes = fileData.getAll("documents").map((file) => {
        return file.size;
      });
      const totalFileSize = fileSizes.reduce(
        (acc, fileSize) => acc + fileSize,
        0
      );
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL_DOCUMENTSERVICE}/api/v1/documents/?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}`;
        const token = userInfo?.accessToken;
        const response = await axios({
          url: baseUrl,
          method: "POST",
          responseType: "json",
          headers: { Authorization: "Bearer " + token },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.total;
            if (totalLength !== null) {
              setLoading(true);
              const progress = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
              // Update progress for this specific file
              setProgressMap((prevProgressMap) => ({
                ...prevProgressMap,
                [data.docTypeId]: { progress, fileNames },
              }));
            }
          },
          data: fileData,
        });

        if (response.status === 200) {
          setLoading(false);
          const firmType = userInfo?.firmTypeId;
          firmType === 1 &&
            ReactGA.event(`upload_other_documents_confirmation`, {
              category: `${ROLE.client} - 3 Level`,
              action: `Upload other Documents Confirmation - ${ROLE.client} - 3 Level`,
              label: `Upload other Documents Confirmation - ${ROLE.client} - 3 Level`,
              other_docs: fileNames.length,
              other_filesize: totalFileSize,
            });

          firmType === 2 &&
            ReactGA.event(`upload_other_documents_confirmation`, {
              category: `${ROLE.client} - 2 Level`,
              action: `Upload other Documents Confirmation - ${ROLE.client} - 2 Level`,
              label: `Upload other Documents Confirmation - ${ROLE.client} - 2 Level`,
              other_docs: fileNames.length,
              other_filesize: totalFileSize,
            });
          dispatch(
            documentApiManagement.util.invalidateTags(["getAllDocsByRootId"])
          );
          toast.success(response?.data?.message);
          setTimeout(() => {
            setProgressMap((prevProgressMap) => {
              const updatedProgressMap = { ...prevProgressMap };
              delete updatedProgressMap[data.docTypeId];
              return updatedProgressMap;
            });
          }, 2000);
        }
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || `File uploading failed. Try again!`
        );
        setProgressMap((prevProgressMap) => {
          const updatedProgressMap = { ...prevProgressMap };
          delete updatedProgressMap[data.docTypeId];
          return updatedProgressMap;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadotherdocument]
  );
  useEffect(() => {
    if (isSubmitted) {
      if (selectedDropdown === "DT_001") {
        const data = {
          rootDocId: location?.state?.doc?.rootDocId,
          docTypeId: selectedDropdown,
          clientId: location?.state?.doc?.userId,
          userId: userInfo?.userId,
          orgDocsFlag: radioValue === "Yes" ? true : false,
        };
        const fileData = new FormData();
        fileData.append("file", selectedDocs);
        uploaddocumentwillFiles({ fileData, data });
        setSubmitClicked(false);
        setRadioSelect(false);
      }
    }
  }, [
    isSubmitted,
    selectedDocs,
    location?.state?.doc,
    selectedDropdown,
    radioValue,
    uploaddocumentwillFiles,
    uploaddocumentotherFiles,
    userInfo?.userId,
  ]);
  useEffect(() => {
    if (location?.state?.user) {
      getDocumentTypes();
      setUserDetails(location?.state?.user);
      setItems([
        {
          id: "item-1",
          link: client_LandingPage,
          content: clientTitle,
          active: false,
          state: { user: location?.state?.user },
        },
        {
          id: "item-2",
          link: client_uploadDocuments,
          content: location?.state?.doc?.rootDocName,
          active: true,
          state: { doc: location?.state?.doc, user: location?.state?.user },
        },
      ]);
    }
  }, [location?.state, getDocumentTypes, data?.fullName]);
  useEffect(() => {
    if (selectedDropdown === "DT_001") {
      setAcceptedFiles(wills);
      setMultiple(false);
      setMaxUploadFiles(1);
    } else if (selectedDropdown === "DT_002") {
      setAcceptedFiles(videosigning);
      setMultiple(false);
      setMaxUploadFiles(1);
    } else {
      setAcceptedFiles(defaultDocType);
      setMultiple(true);
      setMaxUploadFiles(10);
    }
  }, [selectedDropdown]);
  const handleUploadDocumentSelect = (selValue) => {
    setDropdown(selValue);
  };
  if (documentTypeError) {
    return (
      <Container data-testid="error-check-view" className="pt-5">
        <Alert variant={"danger"} className="mt-5">
          Something Happened in the Server and Server status -{" "}
          {documentTypeError?.status}. {documentTypeError?.data}
        </Alert>
      </Container>
    );
  }
  return (
    <>
      <div
        className="attorney-client-title-page-container  px-lg-4 px-xl-4"
        data-testid="attonery-client-title"
      >
        <Container fluid>
          <div className="attoner-client-title px-2 px-lg-4 px-md-4 px-xl-4">
            <EmployeeTitleBar
              items={items}
              hasButton={false}
              title={`${location?.state?.doc?.rootDocName}`}
            />
          </div>
        </Container>
      </div>
      <div className="firm-page-outer-container px-lg-4 px-xl-4">
        <Container fluid>
          <div className="firm-page-container upload-client-container px-1 px-lg-4 px-xl-4">
            <div className="document-type-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <Form className="custom-form employee-form type-2 mt-3">
                <Row>
                  <Col lg={8} md={8} sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Label
                        htmlFor="select-documentType"
                        className="required mb-0"
                      >
                        Select document type
                      </Form.Label>
                      <Form.Select
                        name="documentType"
                        className="form-input input-border-radius-unset select-placeholder"
                        data-testid="select-documentType"
                        id="select-documentType"
                        onChange={(e) => {
                          handleUploadDocumentSelect(e.target.value);
                        }}
                      >
                        <option value="" hidden></option>
                        {documentTypes &&
                          documentTypes.length > 0 &&
                          documentTypes.map((group, index) => (
                            <option
                              value={group.rootDocTypeId}
                              key={"docType" + index}
                            >
                              {group.rootDocTypeName}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={4} sm={12} className="browse-upload-button">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} data-testid="dropzone" />
                      <Button
                        className={`custom-button btn-standard custom-file-upload ${
                          selectedDropdown === "" && "btn-disable"
                        } mt-0 mt-lg-4 mt-md-4`}
                        type="button"
                        onClick={open}
                      >
                        Browse & Upload
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="document-list-container px-2 px-lg-4 px-md-4 px-xl-1 pb-2">
              <DocumentDetails
                allDocumentsData={allDocumentsData}
                userDetails={userDetails}
                locationState={{
                  doc: location?.state?.doc,
                  user: location?.state?.user,
                  docType: location?.state?.docType,
                }}
              />
            </div>
          </div>
        </Container>
      </div>
      {(isLoading || documentTypeLoading || getAllDocsLoading) && (
        <Loader data-testid="loader" />
      )}

      {isUploadLoading && <Loader />}

      {Object.entries(progressMap).length > 0 && (
        <div className="progress-doc-container" style={{ zIndex: "99999999" }}>
          {Object.entries(progressMap).map(
            ([docId, { progress, fileNames }]) => (
              <div key={docId} className="progress-bar-document">
                <div className="d-flex flex-column">
                  <div>
                    <div>
                      <b>
                        {fileNames.length === 1
                          ? `${fileNames.length} document is uploading`
                          : `${fileNames.length} documents are uploading`}
                      </b>
                    </div>
                    {/* <div>
                      [ {fileNames.map((data, index) => (
                        <span key={index}>
                          {data}
                          {index !== fileNames.length - 1 && ","}{" "}
                        </span>
                      ))} ]
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center">
                    <progress value={progress} max={100} />{" "}
                    <div className="ps-2">{progress}%</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      )}

      <DocumentPreview
        showModal={showModal}
        setShowCallback={() => setShowModal(false)}
        fileContent={content}
        fileName={fileName}
        setSubmitCallback={() => setSubmitClicked(true)}
        setRadioCallback={(val) => {
          setRadioValue(val);
          sessionStorage.setItem("orgDoc", val);
        }}
        initialRadioSelect={radioSelect}
        isLoading={uploadLoading}
        isOthersLoading={uploadOthersLoading}
        selectedDropdown={selectedDropdown}
        selectedDocs={selectedDocs}
      />
    </>
  );
}
