import { documentApiSlice } from "./documentApiSlice";
export const documentApiManagement = documentApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getfoldersByid: builder.query({
      query: (userid) => ({
        url: `/users/${userid}/root-docs`,
        method: "GET",
      }),
      providesTags: ["getfoldersByid"],
    }),
    getDocumentTypeByDocTypeid: builder.query({
      query: (doctypeid) => ({
        url: `/root-docs/${doctypeid}/docTypes`,
        method: "GET",
      }),
      providesTags: ["getDocumentTypeByDocTypeid"],
    }),
    uploadwilldocument: builder.mutation({
      query: ({ fileData, data }) => ({
        url: `/will-documents?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}&orgDocsFlag=${data.orgDocsFlag}`,
        method: "POST",
        body: fileData,
      }),
      prepareHeaders: (headers) => {
        headers.set("Content-Type", "multipart/form-data");
        return headers;
      },
      invalidatesTags: ["getAllDocsByRootId"],
    }),
    uploadotherdocument: builder.mutation({
      query: ({ fileData, data }) => ({
        url: `/?rootDocId=${data.rootDocId}&docTypeId=${data.docTypeId}&clientId=${data.clientId}&userId=${data.userId}`,
        method: "POST",
        body: fileData,
      }),
      prepareHeaders: (headers) => {
        headers.set("Content-Type", "multipart/form-data");
        return headers;
      },
      invalidatesTags: ["getAllDocsByRootId"],
    }),
    getAllDocsByRootId: builder.query({
      query: ({ rootDocId, userId }) => ({
        url: `/root-documents/${rootDocId}/documents?userId=${userId}`,
        method: "GET",
      }),
      providesTags: ["getAllDocsByRootId"],
    }),
    downloadFile: builder.query({
      query: (docId) => ({
        url: `${docId}`,
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
    }),
    deleteDocument: builder.mutation({
      query: (docId) => ({
        url: `${docId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getAllDocsByRootId"],
    }),
    shareDocument: builder.mutation({
      query: (data) => ({
        url: `/${data.docId}/share?userId=${data.userId}&toAddress=${data.toAddress}&recipientName=${data.recipientName}&duration=${data.duration}`,
        method: "POST",
      }),
    }),
  deathClaim: builder.mutation({
    query: ({fileData, data}) => ({
      url: `death-claim/${data.clientId}?userId=${data.userId}`,
      method: "POST",
      body: fileData,
    }),
  }),
  deathCertificateUploaded: builder.mutation({
    query: ({clientId}) => ({
      url: `death-claim/certificate/${clientId}`,
      method: "GET",
    }),
  }),
  getClientAllDocsByRootId: builder.query({
    query: ({ rootDocId, userId, role }) => ({
      url: `/root-documents/${rootDocId}/documents?userId=${userId}&role=${role}`,
      method: "GET",
    }),
    providesTags: ["getClientAllDocsByRootId"],
  }),
  getClientfoldersByid: builder.query({
    query: ({userid, role}) => ({
      url: `/users/${userid}/root-docs?role=${role}`,
      method: "GET",
    }),
    providesTags: ["getClientfoldersByid"],
  }),
  updateWillRetainflag: builder.mutation({
    query: (data) => ({
      url: `/orgDoc?docId=${data.docId}&userId=${data.userId}&orgDocsFlag=${data.orgDocsFlag}`,
      method: "PUT",
      body:data
    }),
  })
  }),
});

export const {
  useLazyGetfoldersByidQuery,
  useLazyGetDocumentTypeByDocTypeidQuery,
  useUploadotherdocumentMutation,
  useUploadwilldocumentMutation,
  useGetAllDocsByRootIdQuery,
  useLazyDownloadFileQuery,
  useDownloadFileQuery,
  useDeleteDocumentMutation,
  useDeathClaimMutation,
  useShareDocumentMutation,
  useDeathCertificateUploadedMutation,
  useGetClientAllDocsByRootIdQuery,
  useLazyGetClientfoldersByidQuery,
  useLazyGetAllDocsByRootIdQuery,
  useUpdateWillRetainflagMutation,
  useGetfoldersByidQuery
 } = documentApiManagement;
